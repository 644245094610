import { gql } from "@apollo/client";

export const CLAIM_REFERRAL_MUTATION = gql`
  mutation ReferralClaim($referralClaim: CreateReferralClaimInput!) {
    createReferralClaim(data: $referralClaim) {
      id
      email
      referralCodeId
      createdAt
    }
  }
`;

export const GET_REFERRAL_INFO = gql`
  query GetReferralInfo($referralCodeId: String!) {
    getReferralInfo(referralCodeId: $referralCodeId) {
      referrer {
        email
        firstName
        lastName
      }
      source
    }
  }
`;

export const CHECK_USER_FOR_REFERRAL_CLAIM_MUTATION = gql`
  mutation CheckUserForReferralClaim {
    checkUserForReferralClaim {
      id
      email
      referralCodeId
      createdAt
    }
  }
`;

export const CREATE_REFERRAL_CODE_MUTATION = gql`
  mutation ReferralCode($referralCode: CreateReferralCodeInput!) {
    createReferralCode(data: $referralCode) {
      referralCode
      _count {
        referralClaims
      }
    }
  }
`;

export const GET_USER_REFERRALS = gql`
  query GetUserReferrals($referralInput: GetUserReferralsInput!) {
    getUserReferrals(data: $referralInput) {
      numOfEntries
      referrals {
        email
        status
      }
    }
  }
`;
