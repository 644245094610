"use client";

import { GetUserReferralsQuery, Platform } from "@/__generated__/graphql";
import { GET_USER_REFERRALS } from "@/api/referrals";
import { VERCEL_BLOB_URL } from "@/constants";
import { useAuthContext } from "@/context/AuthContext";
import useAnalytics from "@/hooks/useAnalytics";
import { useGiveaway } from "@/hooks/useGiveaway";
import { useQuery } from "@apollo/client";
import { ShareIcon } from "@phiaco/phia-ui";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import Button from "../../components/shared/Button";

const imageUrls = [
  `${VERCEL_BLOB_URL}/Share%20Banner/share_bag_1-KdhANsVytZI9J4h3LExhK6K3z7HFlX.png`,
  `${VERCEL_BLOB_URL}/Share%20Banner/share_bag_2-HhfUR4OKnMheAZVkf5sHD6kQxONauD.png`,
  `${VERCEL_BLOB_URL}/Share%20Banner/share_bag_3-SFg4dGeTZhcL2y4Evx554LhIjH6YQw.png`,
];

interface ShareBarProps {
  variant: "landing" | "profile";
  className?: string;
}

const ShareBar: React.FC<ShareBarProps> = ({ className, variant }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isLoggedIn } = useAuthContext();

  const { data } = useQuery<GetUserReferralsQuery>(GET_USER_REFERRALS, {
    variables: {
      referralInput: {
        source: Platform.MarketplaceWeb,
      },
    },
    skip: !isLoggedIn,
  });
  const numReferrals = data?.getUserReferrals.numOfEntries || 0;

  const { handleInvite: inviteFriends, eventName } = useGiveaway();

  const { logMixpanelEvent } = useAnalytics();

  // Function to change the current image for animation
  useEffect(() => {
    // Function to change the current image index
    const changeImage = () => {
      // Only update the index if the tab is visible to prevent any stale animations
      if (document.visibilityState === "visible") {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % imageUrls.length);
      }
    };

    // Set up an interval to change the image every 1 second
    const intervalId = setInterval(changeImage, 750);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const headingText =
    numReferrals > 0
      ? `You have ${numReferrals} ${pluralize("entry", numReferrals)} in our giveaway`
      : "Want to win a free designer bag?";
  const paraText =
    numReferrals > 0
      ? `Share Phia with your friends to increase your chances of winning a designer bag.`
      : `Share Phia with your friends to enter our giveaway of 5 luxury bags.`;

  const imageContainerClassName =
    variant === "landing"
      ? "relative flex w-[80px] h-[110px]"
      : "relative flex w-[80px] h-[110px] md:absolute md:left-0 md:top-[1.5rem] ";
  const headingClassName =
    variant === "landing"
      ? "heading-serif-sm text-cn-onColor md:heading-serif-md lg:heading-serif-lg"
      : "heading-serif-sm text-cn-onColor";
  const textContainerClassName =
    variant === "landing"
      ? "flex w-full flex-col gap-md sm:gap-xs"
      : "md:ml-[80px] ml-0 flex w-full flex-col gap-md sm:gap-xs";
  return (
    <div
      onClick={() => {
        logMixpanelEvent({ name: eventName });
        inviteFriends();
      }}
      className={cn(
        "relative flex cursor-pointer flex-col items-center gap-xl overflow-hidden rounded-sm bg-bg-accent p-lg sm:p-xl md:flex-row",
        className
      )}>
      <div className="flex flex-1 items-center justify-center gap-xl sm:justify-start">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentImageIndex} // This key is necessary for AnimatePresence to track changes
            initial={{ opacity: 0.6 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className={imageContainerClassName}>
            <Image
              src={imageUrls[currentImageIndex]}
              alt="hand bag"
              fill
              priority
              className="object-cover object-center shadow-shallowBelow"
            />
          </motion.div>
        </AnimatePresence>
        <div className="flex w-full flex-1 items-center justify-between gap-xl">
          <div className={textContainerClassName}>
            <p className={headingClassName}>{headingText}</p>
            <p className="para-sm text-cn-onColor md:para-lg sm:text-cn-lightAccent">
              {paraText}
            </p>
          </div>
          <Button
            eventName={eventName}
            onClick={inviteFriends}
            variant="overlay"
            innerClassName="label-md"
            className="hidden w-fit whitespace-nowrap sm:flex">
            <ShareIcon color="white" size={16} className="mr-2" /> Share Phia
          </Button>
        </div>
      </div>
      <Button
        eventName={eventName}
        onClick={inviteFriends}
        variant="overlay"
        innerClassName="label-md py-2 w-full"
        className="w-full sm:hidden">
        <ShareIcon color="white" size={16} className="mr-2" /> Share Phia
      </Button>
    </div>
  );
};

export default ShareBar;
