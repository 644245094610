"use client";

import Button from "@/components/shared/Button";
import useAnalytics from "@/hooks/useAnalytics";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { CustomerIoEventName, MixpanelEventName } from "@/types";
import { CheckIcon, Input, useToast } from "@phiaco/phia-ui";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .optional()
    .email("We don't recognize that email. Please try again."),
});

const NewsletterSignUp = () => {
  const toast = useToast();
  const { logMixpanelEvent, logUserEventToCustomerIo } = useAnalytics();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isVisible = useIntersectionObserver({
    targetSelector: "#newsletter_signup",
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  });

  useEffect(() => {
    if (isVisible) {
      logMixpanelEvent({
        name: MixpanelEventName.LANDING_PAGE_SECTION_VIEWED,
        properties: { section_name: "newsletter_signup" },
      });
    }
  }, [isVisible, logMixpanelEvent]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: EmailSchema,
    onSubmit: ({ email }: { email: string }) => {
      toast({
        title: "You've subscribed!",
        description: "Thank you for joining our newsletter.",
        includeCloseButton: true,
      });
      logUserEventToCustomerIo({
        name: CustomerIoEventName.NEWSLETTER_SIGNUP,
        properties: { email },
      });
      formik.resetForm();
      setIsSubmitted(true);
    },
  });

  return (
    <form
      id="newsletter_signup"
      onSubmit={formik.handleSubmit}
      className="flex max-w-[720px] flex-col gap-8 px-lg pb-10 text-center sm:mx-auto sm:gap-20 sm:py-[120px]">
      <div className="display-xs text-left sm:display-sm md:display-md sm:text-center">
        Get <i className="text-cn-accent">exclusive drops</i>
        <br /> and recommendations to <br className="hidden sm:visible" />
        your inbox.
      </div>
      {isSubmitted ? (
        <div className="grid h-11 w-full grid-cols-[1fr_126px] items-center gap-4 rounded-sm border border-solid border-border-transparent bg-bg-positive px-5">
          <p className="para-md h-5 w-full rounded-none border-none p-0 text-left text-cn-primary focus-within:outline-none">
            Keep an eye on your inbox for our newsletter!
          </p>
          <div className="label-md ml-auto flex max-w-[126px] gap-sm text-cn-positive">
            <CheckIcon />
            <span className="ml-auto">Submitted</span>
          </div>
        </div>
      ) : (
        <Input
          id="email"
          name="email"
          type="string"
          placeholder="Your email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
          rightSlot={
            <Button
              eventName={MixpanelEventName.NEWSLETTER_SIGN_UP_COMPLETED}
              eventProperties={{ email: formik.values.email }}
              variant="secondary"
              isLoading={formik.isSubmitting}
              disabled={!!formik.errors.email}
              type="submit">
              Sign up
            </Button>
          }
          className="py-2"
          containerClassName="h-[44px]"
        />
      )}
    </form>
  );
};

export default NewsletterSignUp;
