// hooks/useIntersectionObserver.js

import { useEffect, useState } from "react";

interface IntersectionObserverOptions {
  targetSelector: string;
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}

const useIntersectionObserver = (options: IntersectionObserverOptions) => {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.isIntersecting),
      options
    );

    const element = document.querySelector(options.targetSelector);
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [options]);

  return isIntersecting;
};

export default useIntersectionObserver;
