import { logoData } from "./utils";

const Logos: React.FC = () => {
  return (
    <div className="no-scrollbar mb-[2.5rem] grid w-full auto-cols-max grid-flow-col gap-14 overflow-x-auto lg:gap-[70px]">
      {logoData.map(({ Icon, id }) => (
        <Icon key={id} className="max-w-none" />
      ))}
    </div>
  );
};

export default Logos;
