"use client";

import { ProductSearchOrigin } from "@/__generated__/graphql";
import { buildProductLink } from "@/app/_components/HeroBannerV2/utils";
import useAnalytics from "@/hooks/useAnalytics";
import { useUnauthedSearchCount } from "@/hooks/useUnauthedSearchCount";
import { MixpanelEventName } from "@/types";
import { SealCheckIcon } from "@phiaco/phia-ui";
import { customCN as cn, formatPrice } from "@phiaco/phia-ui/dist/util";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { FC } from "react";

interface ProductBoxProps {
  title: string;
  image: string;
  productUrl: string;
  originalPrice: number;
  website: string;
  savePercent?: number;
  isLarge?: boolean;
  isOriginal?: boolean;
  className?: string;
}

const ProductBox: FC<ProductBoxProps> = ({
  title,
  image,
  productUrl,
  originalPrice,
  website,
  savePercent,
  isLarge,
  isOriginal,
  className,
}) => {
  const link = buildProductLink({
    title,
    image,
    productUrl,
    originalPrice,
    website,
    source: ProductSearchOrigin.ShowcasedProducts,
  });
  const priceAfterDiscount = formatPrice(
    originalPrice! * (1 - (savePercent || 0) / 100),
    false
  );
  const router = useRouter();
  const { logMixpanelEvent } = useAnalytics();
  const { setCookie } = useUnauthedSearchCount();

  return (
    <div
      onClick={() => {
        logMixpanelEvent({
          name: MixpanelEventName.LANDING_PAGE_SHOWCASED_PRODUCT_CLICKED,
        });
        setCookie();
        router.push(link);
      }}
      className={cn(
        "relative flex cursor-pointer flex-col justify-between rounded-sm p-2 md:p-lg",
        isLarge ? "h-full w-full" : "h-[280px] w-[280px]",
        className
      )}>
      <Image
        fill
        src={image || ""}
        alt={`${title || "Product"}'s image`}
        className="absolute z-10 rounded-sm object-cover object-center"
      />
      <div className="absolute left-0 top-0 z-20 h-full w-full rounded-sm bg-black bg-opacity-10" />
      <p
        className={cn(
          "relative z-30 flex w-fit flex-col gap-xs rounded-sm px-2 py-1.5 md:flex-row md:items-center md:rounded-full md:px-md",
          isOriginal ? "bg-bg-secondary" : "bg-bg-accent"
        )}>
        <span
          className={cn(
            "label-md",
            isOriginal ? "text-cn-primary" : "text-bg-primary"
          )}>
          {priceAfterDiscount}
        </span>
        <span
          className={cn(
            "para-sm",
            isOriginal ? "text-cn-secondary" : "text-cn-lightAccent"
          )}>
          {isOriginal
            ? "Original Price"
            : `Save ${Math.round(savePercent || 0)}%`}
        </span>
      </p>
      <p className="label-sm relative z-30 flex w-fit items-center gap-[3.6px] text-bg-primary">
        <span>{website}</span>
        <span className="flex flex-shrink-0 items-center justify-center">
          <SealCheckIcon size={16} />
        </span>
      </p>
    </div>
  );
};

export default ProductBox;
