"use client";

import { useAuthContext } from "@/context/AuthContext";
import { routes } from "@/routes";
import { MixpanelEventName, SigninModalSource } from "@/types";
import { useRouter } from "next/navigation";
import { useSigninModal } from "./useSigninModal";

export const useGiveaway = () => {
  const router = useRouter();
  const { isLoggedIn } = useAuthContext();
  const showSignIn = !isLoggedIn;
  const { handleJoinClicked } = useSigninModal(
    SigninModalSource.GiveawayButton
  );
  return {
    handleInvite: () => {
      if (showSignIn) {
        handleJoinClicked();
      } else {
        router.push(routes.GIVEAWAY);
      }
    },
    eventName: MixpanelEventName.NAVIGATED_TO_GIVEAWAY_PAGE,
  };
};
