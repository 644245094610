"use client";

import Container from "@/components/Container";
import ExtensionAd from "./_components/ExtensionAd";
import HeroBannerV2 from "./_components/HeroBannerV2";
import NewsletterSignUp from "./_components/NewsletterSignUp";
import ShareBar from "./_components/ShareBar";

export default function HomePage() {
  return (
    <Container className="flex flex-col gap-6 px-0 sm:gap-20 sm:px-0 sm:pt-2">
      <HeroBannerV2 />
      <ExtensionAd className="hidden pl-lg sm:flex lg:pl-3xl lg:pt-0" />
      <ShareBar className="ml-lg mr-4 lg:ml-3xl" variant="landing" />
      <NewsletterSignUp />
    </Container>
  );
}
