"use client";

import CopyPaste from "@/app/_components/HeroBannerV2/CopyPaste";
import Logos from "@/app/_components/HeroBannerV2/Logos";
import ShowcaseProducts from "@/app/_components/HeroBannerV2/ShowcaseProducts";
import SearchBar from "@/components/Header/SearchBar/SearchBar";
import SearchOverlayContainer from "@/components/Header/SearchOverlay/SearchOverlayContainer";
import { zIndex } from "@/constants";
import { useSearch } from "@/context/SearchContext";
import useLayoutSpacing from "@/hooks/useLayoutSpacing";
import usePhiaPathname from "@/hooks/usePhiaPathname";
import { routes } from "@/routes";
import { useTailwindBreakpoint } from "@phiaco/phia-ui";
import { customCN as cn } from "@phiaco/phia-ui/dist/util";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const HeroBannerV2: React.FC = () => {
  // Build-in hooks
  const pathname = usePhiaPathname();
  // Custom hooks
  const { screenSize } = useTailwindBreakpoint();
  const { isSearchOverlayVisible } = useSearch();
  const { landingScrolled } = useLayoutSpacing();
  // Custom variables
  const isLargeScreen = screenSize === "xl" || screenSize === "2xl";
  const searchBarSize = isLargeScreen ? "lg" : "sm";
  const isLandingPageScrolled = landingScrolled && pathname === routes.HOME;

  return (
    <>
      <div className="flex w-full flex-col items-center px-xl md:pt-3xl lg:px-0">
        <h1 className="display-sm max-w-[310px] text-center md:max-w-full lg:max-w-none lg:text-[5rem] lg:leading-[110%] lg:-tracking-[3.2px]">
          Shop smarter with <span className="italic">resale</span>
        </h1>
        <div
          className={cn(
            "relative mt-3xl flex w-full flex-col items-center justify-center rounded-[22px] transition-all lg:mt-[5.5rem] lg:min-w-[905px] lg:max-w-[905px]",
            isSearchOverlayVisible && !isLandingPageScrolled
              ? "shadow-none"
              : "shadow-[0px_0px_32px_0px_rgba(8,75,231,0.20),_0px_4px_48px_0px_rgba(0,0,0,0.10)] hover:shadow-[0px_6px_48px_0px_rgba(0,0,0,0.16)]"
          )}>
          {/* Transitional overlay element */}
          {isSearchOverlayVisible && !isLandingPageScrolled && (
            <div
              className={cn(
                "absolute left-0 top-0 z-[92] h-[282px] w-full rounded-[22px] shadow-[0px_6px_48px_0px_rgba(0,0,0,0.16)] md:h-[300px]",
                "transition-transform duration-500 ease-out",
                isSearchOverlayVisible
                  ? "translate-y-0 opacity-100"
                  : "-translate-y-full opacity-0"
              )}
            />
          )}

          <SearchBar
            className={cn(
              "relative z-[99] h-[56px] w-full border-transparent md:h-[74px] lg:max-w-[905px] lg:text-[22px]",
              "duration-400 transition-all",
              isSearchOverlayVisible && "rounded-b-none"
            )}
            focusOnMount={!isLandingPageScrolled}
            isLandingPage
            size={searchBarSize}
          />

          <AnimatePresence>
            {!isLandingPageScrolled && (
              <motion.div
                key="searchOverlayContainer"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.4,
                  ease: "easeInOut",
                }}
                className={cn("absolute top-[56px] w-full md:top-[74px]")}
                style={{
                  zIndex: zIndex.HEADER - 1,
                }}>
                <SearchOverlayContainer />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <CopyPaste />
        <ShowcaseProducts />
      </div>
      <Logos />
    </>
  );
};

export default HeroBannerV2;
