import ProductBox from "@/app/_components/HeroBannerV2/ProductBox";
import { showcasedData } from "@/app/_components/HeroBannerV2/utils";
import { customCN as cn } from "@phiaco/phia-ui/dist/util";

// TODO: Replace this once the ShowcaseProducts component is dynamic
const {
  leftProduct,
  middleProductOriginal,
  middleProductResale,
  rightProduct,
} = showcasedData;

const ShowcaseProducts = () => {
  return (
    <div className="mb-5 mt-[3.75rem] flex w-full justify-center md:px-lg">
      <div className="flex w-full max-w-[1440px] items-center justify-center md:gap-[50px]">
        <ProductBox {...leftProduct} className="hidden xl:flex" />
        <div
          className={cn(
            "flex items-center gap-md p-md md:gap-xl md:p-xl",
            "h-[220px] w-full rounded-xl bg-bg-primary md:h-[368px] md:w-[712px]",
            "shadow-[0px_24px_60px_0px_rgba(0,0,0,0.16)]"
          )}>
          <ProductBox {...middleProductOriginal} isLarge isOriginal />
          <ProductBox {...middleProductResale} isLarge />
        </div>
        <ProductBox {...rightProduct} className="hidden xl:flex" />
      </div>
    </div>
  );
};

export default ShowcaseProducts;
