const BlueDot = ({ className = "" }) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <circle cx="5.5" cy="5.5" r="5.5" fill="#084BE7" />
    </svg>
  );
};

const Arrow = ({ className = "" }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2059 12.6187L13.7272 4.63299L21.7129 4.15434"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7633 27.4098C19.6542 25.9755 21.0773 24.0127 21.8527 21.7696C22.6281 19.5266 22.7209 17.104 22.1195 14.8082C21.5181 12.5123 20.2494 10.4464 18.4739 8.87157L13.7268 4.633"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CopyPaste: React.FC = () => {
  return (
    <div className="mt-xl flex flex-col-reverse gap-2 px-9 text-center md:mt-3xl md:flex-col md:px-0">
      <p className="heading-sans-xs relative text-cn-tertiary">
        <span className="relative border border-transparent border-l-cn-accent border-r-cn-accent bg-[rgba(8,75,231,0.15)] text-cn-primary">
          <BlueDot className="absolute -left-1.5 -top-[11px]" />
          &nbsp;Copy and paste&nbsp;
          <BlueDot className="absolute -bottom-[11px] -right-1.5" />
        </span>
        &nbsp; a product link to find it resale
        <Arrow className="absolute -right-[35px] -top-[17px]" />
      </p>
      <div
        className="para-xs ml-5 flex w-fit items-center rounded-sm border border-border-transparent bg-bg-primary text-cn-primary shadow-lg"
        style={{
          textShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
        }}>
        <span className="inline-flex h-8 items-center px-md">Copy</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2"
          height="32"
          viewBox="0 0 2 32"
          fill="none">
          <path d="M1 0V32" stroke="black" strokeOpacity="0.08" />
        </svg>
        <span className="inline-flex h-8 items-center px-md">Paste</span>
      </div>
    </div>
  );
};

export default CopyPaste;
